'use client';

import { Suspense } from 'react';
import dynamic from 'next/dynamic';
import { AppRouterCacheProvider } from '@mui/material-nextjs/v13-appRouter';
import CssBaseline from '@mui/material/CssBaseline';
import { SessionProvider } from '@side/next-auth/react';
import type { Session } from '@side/next-auth';
import { ToastProvider } from '@side/pantry';
import { ApolloProvider } from '@apollo/client';
import { PantryProvider } from 'src/modules/pantry/PantryProvider';
import { LaunchDarkly } from 'src/modules/launchdarkly/provider';
import { ThemeProvider } from 'src/components/ThemeProvider';
import { SetupMaze } from 'src/components/Initialization/SetupMaze';
import { AppRefreshModal } from 'src/components/AppRefreshModal';
import { client } from 'src/modules/graphql';
import { initDatadog } from 'src/modules/datadog';
import { useSelectedLayoutSegment } from 'next/navigation';

initDatadog();

const Initialization = dynamic(
  () =>
    import('src/components/Initialization').then(
      (exported) => exported.Initialization,
    ),
  { ssr: false },
);

/**
 * Root Layout component which mirrors pages/_app.tsx for app router
 * @param props
 * @returns RootLayout React component
 */
export function RootClientProvider({
  children,
  session,
}: {
  session: Session | null;
  children: React.ReactNode;
}) {
  const routeSegment = useSelectedLayoutSegment();
  return (
    <AppRouterCacheProvider options={{ enableCssLayer: true }}>
      <SetupMaze />
      <ApolloProvider client={client}>
        <ToastProvider />
        <SessionProvider
          session={session}
          basePath="/identity/api/auth"
          pageOptions={{ isPublic: routeSegment !== '(auth-required)' }}
        >
          <Suspense fallback={null}>
            <Initialization />
          </Suspense>
          <LaunchDarkly>
            <PantryProvider>
              <ThemeProvider>
                <CssBaseline />
                {children}
                <AppRefreshModal />
              </ThemeProvider>
            </PantryProvider>
          </LaunchDarkly>
        </SessionProvider>
      </ApolloProvider>
    </AppRouterCacheProvider>
  );
}
